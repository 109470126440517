<template>
  <div class="bureau-container bureau-parameterization" v-show="loadCompleted">
    <div class="bureau-container-header">
      <p class="bureau-container-header-title">
        {{ currentBureau.NomeConsulta }}
      </p>
      <p class="bureau-container-header-information">
        Informe abaixo a ordenação de integração da consulta.
      </p>
    </div>

    <el-alert
      class="bureau-container-warning is-small"
      size="sm"
      type="warning"
      show-icon
    >
      Lembre-se de que a ordem aplicada abaixo será levada em conta no momento
      da consulta, caso seja necessário.
    </el-alert>

    <div class="bureau-container-cards">
      <transition-group name="fade" tag="div">
        <div
          v-for="(currentParamBureau, index) in currentParametrosBureaus"
          :key="currentParamBureau.Sequencia"
          :class="{ 'margin-left': index !== 0 }"
          class="bureau-container-wrapper"
        >
          <div class="bureau-container-wrapper-card">
            <img
              src="@/assets/images/icons/DotsSixVertical.svg"
              alt="Ícone de pontuação vertical"
              width="28"
              height="28"
            />

            <div class="bureau-container-wrapper-card-content">
              <p class="bureau-container-wrapper-card-content-title">
                {{ `Consulta nº ${index + 1} *` }}
              </p>
              <el-select
                v-model="currentParamBureau.BureauId"
                placeholder="Selecione"
                @change="atualizarBureauNome(currentParamBureau, $event)"
                class="bureau-container-wrapper-card-content-select"
              >
                <el-option
                  v-for="bureau in listaBureaus"
                  :key="bureau.Id"
                  :label="bureau.Nome"
                  :value="bureau.Id"
                >
                </el-option>
              </el-select>
            </div>

            <div
              class="bureau-container-wrapper-card-content-delete"
              @click="excluirConsulta(currentParamBureau)"
              v-if="index !== 0"
            >
              <i class="el-icon-delete-solid"></i>
            </div>
          </div>

          <el-button
            class="bureau-container-wrapper-card-add-button"
            @click="adicionarConsulta(currentParamBureau)"
            v-if="index === currentParametrosBureaus.length - 1"
          >
            Adicionar consulta
          </el-button>
        </div>
      </transition-group>
    </div>

    <div class="bureau-container-footer">
      <div class="bureau-container-footer-goBack">
        <el-button
          type="primary"
          class="bureau-container-save-button"
          @click="voltarBureausConsulta"
        >
          Voltar
        </el-button>
      </div>

      <div class="bureau-container-footer-save">
        <el-button
          type="primary"
          class="bureau-container-save-button"
          @click="salvarAlteracoes"
        >
          Salvar alterações
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as Utils from "@/utils/utils";

export default {
  name: "parametrizacao-bureaus",
  data() {
    return {
      currentBureau: {},
      currentParametrosBureaus: [],
      loadCompleted: false
    };
  },
  computed: {
    ...mapGetters("parametrizacao-module", ["listaBureaus", "listaConsultas"])
  },
  methods: {
    ...mapMutations("parametrizacao-module", ["updateBureauQueryList"]),
    ...mapActions("parametrizacao-module", [
      "getBureaus",
      "addBureauQuery",
      "deleteBureauQuery",
      "saveBureauChanges",
      "getBureausListByTipoConsultaId"
    ]),

    pegarBureauAtual() {
      this.currentBureau = this.listaConsultas.find(
        consulta => consulta.TipoConsultaId == this.$route.params.tipoConsultaId
      );

      this.clonarParametrosBureaus();
    },

    clonarParametrosBureaus() {
      this.currentParametrosBureaus = Utils.deepClone(
        this.currentBureau.ParametrosBureaus
      );
    },

    atualizarBureauNome(paramBureau, bureauIdSelecionado) {
      const bureauSelecionado = this.listaBureaus.find(
        bureau => bureau.Id === bureauIdSelecionado
      );

      if (bureauSelecionado) {
        paramBureau.Bureau = bureauSelecionado.Nome;
      }
    },

    extrairObjetoDeArray(array) {
      return array.find(() => true);
    },

    verificarConsultasDuplicadas(consultas) {
      const temConsultaDuplicada = consultas.some(
        (consulta, index, self) =>
          self.findIndex(parametro => parametro.Bureau === consulta.Bureau) !==
          index
      );

      if (temConsultaDuplicada) {
        this.$onpoint.errorModal(
          "Não é permitido salvar consultas duplicadas!"
        );
        return false;
      }

      return true;
    },

    verificarConsultasVazias(consultas) {
      const temConsultaVazia = consultas.some(
        consulta => consulta.Bureau === "" || consulta.Bureau == null || consulta.Bureau == 0
      );

      if (temConsultaVazia) {
        this.$onpoint.errorModal("Não é permitido salvar consultas vazias!");
        return false;
      }

      return true;
    },

    async adicionarConsulta(currentParamBureau) {
      let data = {
        SeguradoraId: currentParamBureau.SeguradoraId,
        TipoConsultaId: currentParamBureau.TipoConsultaId
      };

      await this.$onpoint.loading(async () => {
        return await this.addBureauQuery(data)
          .then(response => this.extrairObjetoDeArray(response.ListaConstultas))
          .then(response => this.updateBureauQueryList(response))
          .then(() => this.pegarBureauAtual())
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    async excluirConsulta(currentParamBureau) {
      await this.$onpoint.loading(async () => {
        return await this.deleteBureauQuery(currentParamBureau)
          .then(response => this.extrairObjetoDeArray(response.ListaConstultas))
          .then(response => this.updateBureauQueryList(response))
          .then(() => this.pegarBureauAtual())
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },

    voltarBureausConsulta(){
      this.$router.push({
        name: "param-bureaus-consulta"
      });
    },

    async salvarAlteracoes() {
      if (!this.verificarConsultasVazias(this.currentParametrosBureaus)) return;

      if (!this.verificarConsultasDuplicadas(this.currentParametrosBureaus)) return;

      await this.$onpoint.loading(async () => {
        return await this.saveBureauChanges(
          this.currentParametrosBureaus
        )
        .then(async () => await this.getBureaus())
        .then(() => {
          this.$onpoint.successModal("Alterações foram salvas com sucesso!");
        })
        .catch(error => {
          this.$onpoint.errorModal(error.response.data.Errors);
        });
      })
    },

    async pegarListaBureaus(){
      const tipoConsultaId = this.$route.params.tipoConsultaId;

      try {
        await this.getBureausListByTipoConsultaId(tipoConsultaId);
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      }
    },

    async init() {
      try {
        await this.pegarListaBureaus()
        this.pegarBureauAtual();
      } catch (error) {
        this.$onpoint.errorModal(error.response.data.Errors);
      } finally {
        this.loadCompleted = true;
      }
    }
  },
  async created() {
    this.$appInsights.trackPageView({
      name: "parametrizacao-bureaus",
      url: window.location.href
    });

    await this.$onpoint.loading(async () => {
      return await this.init();
    });
  }
};
</script>

<style scoped lang="scss">
.margin-left {
  margin-left: 40px !important;
}

.bureau-container {
  background: #fff;
  border-radius: 16px;
  margin: 0;
  padding: 42px 24px 35px;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.03);

  &-header {
    margin-bottom: 24px;

    &-title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      padding: 0;
      color: #4b5563;
    }

    &-information {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      padding: 0;
    }
  }

  &-cards {
    min-height: calc(445px - 84px);
    margin: 42px 0 64px;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-card {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25px;
      border: 1px solid #d1d5db;
      margin-right: 32px;
      border-radius: 16px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07);

      &-content {
        margin: 0 32px 0 48px;
        width: 460px;

        &-title {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 8px;
        }

        &-delete {
          width: 24px;
          height: 24px;
          cursor: pointer;

          & i {
            width: 100%;
            font-size: 24px;
            color: #e11c48;
          }
        }
      }

      &-add-button {
        width: 258px;
        height: 50px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: rgba(var(--vs-primary), 1);
        padding: 15px 28px;
        border: 2px dashed #d1d5db;
        border-radius: 8px !important;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
      }
    }
  }

  &-footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-goBack{
      margin-right: 30px;
    }
  }
}
</style>
