var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loadCompleted,
          expression: "loadCompleted",
        },
      ],
      staticClass: "bureau-container bureau-parameterization",
    },
    [
      _c("div", { staticClass: "bureau-container-header" }, [
        _c("p", { staticClass: "bureau-container-header-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.currentBureau.NomeConsulta) + "\n    "
          ),
        ]),
        _c("p", { staticClass: "bureau-container-header-information" }, [
          _vm._v(
            "\n      Informe abaixo a ordenação de integração da consulta.\n    "
          ),
        ]),
      ]),
      _c(
        "el-alert",
        {
          staticClass: "bureau-container-warning is-small",
          attrs: { size: "sm", type: "warning", "show-icon": "" },
        },
        [
          _vm._v(
            "\n    Lembre-se de que a ordem aplicada abaixo será levada em conta no momento\n    da consulta, caso seja necessário.\n  "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "bureau-container-cards" },
        [
          _c(
            "transition-group",
            { attrs: { name: "fade", tag: "div" } },
            _vm._l(
              _vm.currentParametrosBureaus,
              function (currentParamBureau, index) {
                return _c(
                  "div",
                  {
                    key: currentParamBureau.Sequencia,
                    staticClass: "bureau-container-wrapper",
                    class: { "margin-left": index !== 0 },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "bureau-container-wrapper-card" },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/icons/DotsSixVertical.svg"),
                            alt: "Ícone de pontuação vertical",
                            width: "28",
                            height: "28",
                          },
                        }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "bureau-container-wrapper-card-content",
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "bureau-container-wrapper-card-content-title",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(`Consulta nº ${index + 1} *`) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _c(
                              "el-select",
                              {
                                staticClass:
                                  "bureau-container-wrapper-card-content-select",
                                attrs: { placeholder: "Selecione" },
                                on: {
                                  change: function ($event) {
                                    return _vm.atualizarBureauNome(
                                      currentParamBureau,
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: currentParamBureau.BureauId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      currentParamBureau,
                                      "BureauId",
                                      $$v
                                    )
                                  },
                                  expression: "currentParamBureau.BureauId",
                                },
                              },
                              _vm._l(_vm.listaBureaus, function (bureau) {
                                return _c("el-option", {
                                  key: bureau.Id,
                                  attrs: {
                                    label: bureau.Nome,
                                    value: bureau.Id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        index !== 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "bureau-container-wrapper-card-content-delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.excluirConsulta(
                                      currentParamBureau
                                    )
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete-solid" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                    index === _vm.currentParametrosBureaus.length - 1
                      ? _c(
                          "el-button",
                          {
                            staticClass:
                              "bureau-container-wrapper-card-add-button",
                            on: {
                              click: function ($event) {
                                return _vm.adicionarConsulta(currentParamBureau)
                              },
                            },
                          },
                          [_vm._v("\n          Adicionar consulta\n        ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }
            ),
            0
          ),
        ],
        1
      ),
      _c("div", { staticClass: "bureau-container-footer" }, [
        _c(
          "div",
          { staticClass: "bureau-container-footer-goBack" },
          [
            _c(
              "el-button",
              {
                staticClass: "bureau-container-save-button",
                attrs: { type: "primary" },
                on: { click: _vm.voltarBureausConsulta },
              },
              [_vm._v("\n        Voltar\n      ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bureau-container-footer-save" },
          [
            _c(
              "el-button",
              {
                staticClass: "bureau-container-save-button",
                attrs: { type: "primary" },
                on: { click: _vm.salvarAlteracoes },
              },
              [_vm._v("\n        Salvar alterações\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }